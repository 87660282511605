import { css } from 'styled-components';

import CalibreRegularWoff from '@fonts/Calibre/Calibre-Regular.woff';
import CalibreRegularWoff2 from '@fonts/Calibre/Calibre-Regular.woff2';
import CalibreMediumWoff from '@fonts/Calibre/Calibre-Medium.woff';
import CalibreMediumWoff2 from '@fonts/Calibre/Calibre-Medium.woff2';
import CalibreSemiboldWoff from '@fonts/Calibre/Calibre-Semibold.woff';
import CalibreSemiboldWoff2 from '@fonts/Calibre/Calibre-Semibold.woff2';

import CalibreRegularItalicWoff from '@fonts/Calibre/Calibre-RegularItalic.woff';
import CalibreRegularItalicWoff2 from '@fonts/Calibre/Calibre-RegularItalic.woff2';
import CalibreMediumItalicWoff from '@fonts/Calibre/Calibre-MediumItalic.woff';
import CalibreMediumItalicWoff2 from '@fonts/Calibre/Calibre-MediumItalic.woff2';
import CalibreSemiboldItalicWoff from '@fonts/Calibre/Calibre-SemiboldItalic.woff';
import CalibreSemiboldItalicWoff2 from '@fonts/Calibre/Calibre-SemiboldItalic.woff2';

import SFMonoRegularWoff from '@fonts/SFMono/SFMono-Regular.woff';
import SFMonoRegularWoff2 from '@fonts/SFMono/SFMono-Regular.woff2';
import SFMonoSemiboldWoff from '@fonts/SFMono/SFMono-Semibold.woff';
import SFMonoSemiboldWoff2 from '@fonts/SFMono/SFMono-Semibold.woff2';

import AgrandirRegularWoff from '@fonts/Agrandir/AgrandirRegular.woff';
import AgrandirTightWoff from '@fonts/Agrandir/AgrandirTight.woff';
import AgrandirGrandHeavyWoff from '@fonts/Agrandir/AgrandirGrandHeavy.woff';

import ApercuMonoBoldWoff from '@fonts/Apercu/ApercuMonoProBold.woff';
import ApercuMonoMediumWoff from '@fonts/Apercu/ApercuMonoProMedium.woff';

import WhyteMediumWoff from '@fonts/Whyte/WhyteMedium.woff';
import WhyteMediumItalicWoff from '@fonts/Whyte/WhyteMediumItalic.woff';
import WhyteRegularWoff from '@fonts/Whyte/WhyteRegular.woff';
import WhyteRegularItalicWoff from '@fonts/Whyte/WhyteRegularItalic.woff';

import SFMonoRegularItalicWoff from '@fonts/SFMono/SFMono-RegularItalic.woff';
import SFMonoRegularItalicWoff2 from '@fonts/SFMono/SFMono-RegularItalic.woff2';
import SFMonoSemiboldItalicWoff from '@fonts/SFMono/SFMono-SemiboldItalic.woff';
import SFMonoSemiboldItalicWoff2 from '@fonts/SFMono/SFMono-SemiboldItalic.woff2';

const calibreNormalWeights = {
  400: [CalibreRegularWoff, CalibreRegularWoff2],
  500: [CalibreMediumWoff, CalibreMediumWoff2],
  600: [CalibreSemiboldWoff, CalibreSemiboldWoff2],
};

const calibreItalicWeights = {
  400: [CalibreRegularItalicWoff, CalibreRegularItalicWoff2],
  500: [CalibreMediumItalicWoff, CalibreMediumItalicWoff2],
  600: [CalibreSemiboldItalicWoff, CalibreSemiboldItalicWoff2],
};

const sfMonoNormalWeights = {
  400: [SFMonoRegularWoff, SFMonoRegularWoff2],
  600: [SFMonoSemiboldWoff, SFMonoSemiboldWoff2],
};

const sfMonoItalicWeights = {
  400: [SFMonoRegularItalicWoff, SFMonoRegularItalicWoff2],
  600: [SFMonoSemiboldItalicWoff, SFMonoSemiboldItalicWoff2],
};

const agrandirNormalWeights = {
  300: [AgrandirTightWoff],
  400: [AgrandirRegularWoff],
};

const agrandirHeavyWeights = {
  600: [AgrandirGrandHeavyWoff],
};

const apercuMonoNormalWeights = {
  400: [ApercuMonoMediumWoff],
  600: [ApercuMonoBoldWoff],
};

const whyteNormalWeights = {
  400: [WhyteRegularWoff],
  600: [WhyteMediumWoff],
};

const whyteItalicWeights = {
  400: [WhyteRegularItalicWoff],
  600: [WhyteMediumItalicWoff],
};

const calibre = {
  name: 'Calibre',
  normal: calibreNormalWeights,
  italic: calibreItalicWeights,
};

const sfMono = {
  name: 'SF Mono',
  normal: sfMonoNormalWeights,
  italic: sfMonoItalicWeights,
};

const agrandir = {
  name: 'Agrandir Regular',
  normal: agrandirNormalWeights,
};

const agrandirGrandHeavy = {
  name: 'Agrandir Grand Heavy',
  normal: agrandirHeavyWeights,
};

const apercu = {
  name: 'Apercu Mono Pro',
  normal: apercuMonoNormalWeights,
};

const whyte = {
  name: 'Whyte',
  normal: whyteNormalWeights,
  italic: whyteItalicWeights,
};

const createFontFaces = (family, style = 'normal') => {
  let styles = '';

  for (const [weight, formats] of Object.entries(family[style])) {
    const woff = formats[0];
    const woff2 = formats[1];

    styles += `
      @font-face {
        font-family: '${family.name}';
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
  }

  return styles;
};

const calibreNormal = createFontFaces(calibre);
const calibreItalic = createFontFaces(calibre, 'italic');

const sfMonoNormal = createFontFaces(sfMono);
const sfMonoItalic = createFontFaces(sfMono, 'italic');

const agrandirNormal = createFontFaces(agrandir);

const agrandirHeavy = createFontFaces(agrandirGrandHeavy);

const apercuNormal = createFontFaces(apercu);

const whyteNormal = createFontFaces(whyte);
const whyteItalic = createFontFaces(whyte, 'italic');

const Fonts = css`
  ${calibreNormal +
  calibreItalic +
  sfMonoNormal +
  sfMonoItalic +
  agrandirNormal +
  agrandirHeavy +
  apercuNormal +
  whyteNormal +
  whyteItalic}
`;

export default Fonts;
