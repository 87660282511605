import React from 'react'

const IconLogo = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 177.91 145.5">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g id="R" transform="translate(11.000000, 5.000000)">
        <path
          d="m161.76,73.77c-2.56-1.21-4.76-1.97-6.64-3.2-5.13-3.35-7.42-8.52-7.74-14.36-.29-5.2.34-10.44.31-15.66-.04-6.05.11-12.16-.68-18.13-1.04-7.9-5.34-11.25-13.38-11.61-5.61-.25-9.21-3.8-8.86-8.87.05-.67.86-1.86,1.29-1.84,6.04.19,12.23-.19,18.09,1.03,10.17,2.11,16.42,10.83,16.51,22.37.06,8.29-.65,16.58-.92,24.88-.08,2.56-.08,5.17.32,7.69.83,5.29,3.92,8.85,9.03,10.56,1.02.34,2.09.53,3.14.8,4.83,1.25,6.77,4.77,5.1,9.49-.18.5-1.06.92-1.68,1.06-2.02.45-4.11.62-6.11,1.15-5.79,1.55-9.26,5.6-9.53,11.61-.21,4.55.06,9.13.18,13.69.15,5.73.62,11.45.52,17.17-.28,15.8-13.59,26.4-29.1,23.38-4.58-.89-6.69-3.66-6.44-8.28.08-1.36.59-1.97,1.97-1.92,2.08.08,4.15.15,6.23.13,7.95-.09,12.68-3.74,13.64-11.62.76-6.22.5-12.57.61-18.87.1-5.87-.31-11.78.23-17.61.58-6.26,4.67-10.04,10.57-11.9.98-.31,1.94-.67,3.35-1.15Z"
          fill="currentColor"
        />
        <path
          d="m15.64,72c.36.21.66.49,1.02.59,8.54,2.58,12.03,9.02,12.64,17.25.34,4.69-.31,9.45-.28,14.18.04,6.47-.1,12.98.58,19.39.81,7.56,5.03,10.88,12.98,11.35,4.95.29,8.57,4.07,8.23,8.75-.05.65-.88,1.79-1.34,1.79-5.31-.06-10.73.31-15.9-.63-10.24-1.86-16.78-10.31-16.98-21.65-.14-7.88.59-15.76.68-23.65.05-4.04.03-8.18-.8-12.09-.99-4.63-4.34-7.52-9.05-8.64-.65-.15-1.28-.36-1.92-.52-4.41-1.12-6.56-5.1-4.98-9.42.17-.46.92-.87,1.47-1,1.69-.4,3.45-.56,5.13-.98,6.46-1.64,9.95-5.79,10.1-12.47.11-4.81-.34-9.63-.33-14.44.01-7.29-.68-14.72.52-21.83C19.56,5.23,31.99-2.24,44.61.6c4.25.96,6.55,4.45,5.65,8.8-.11.53-1.04,1.22-1.63,1.26-1.9.15-3.82.01-5.73.02-8.09.05-12.61,3.64-13.37,11.72-.64,6.75-.33,13.58-.42,20.38-.07,5.22.21,10.46-.25,15.65-.59,6.59-4.65,10.59-10.91,12.46-.64.19-1.26.41-1.88.65-.13.05-.22.23-.42.46Z"
          fill="currentColor"
        />
        <path
          d="m71.7,94.86c2.79,0,5.26-.07,7.73.07.45.03,1.13.86,1.23,1.41.21,1.13.04,2.32.12,3.48.38,5.26,4.32,9.07,9.39,9.12,5.17.05,9.32-3.79,9.72-9.09.12-1.56.02-3.13.02-4.99,2.76,0,5.24-.07,7.7.07.44.02,1.13.88,1.2,1.41,1.45,10.93-6.53,20.96-17.15,21.65-11.02.71-19.9-7.82-19.96-19.17,0-1.16,0-2.31,0-3.94Z"
          fill="currentColor"
        />
        <path
          d="m75.39,74.07c-.92.06-1.41.11-1.89.11-1.66-.01-3.62.48-4.88-.24-.99-.57-1.11-2.65-1.62-4.05-1.77-4.79-6.76-8.12-11.84-7.92-5.28.2-10.13,3.76-11.18,9.01-.6,2.97-1.91,3.62-4.48,3.22-.32-.05-.66,0-.99,0-2.85-.02-3.09-.2-2.58-2.96,1.4-7.61,5.65-13.05,12.96-15.73,7.13-2.61,13.78-1.29,19.57,3.58,4.53,3.81,6.88,8.75,6.95,14.99Z"
          fill="currentColor"
        />
        <path
          d="m103.33,74.19c.13-7.37,3.14-12.86,8.93-16.61,6.89-4.47,14.15-4.59,21.2-.32,6.12,3.71,9.13,9.35,9.58,16.91-2.36,0-4.42.13-6.44-.09-.54-.06-1.26-1.11-1.41-1.82-.85-3.98-2.91-7.04-6.55-8.92-7.24-3.75-15.95.49-17.46,8.65-.34,1.86-1.02,2.34-2.71,2.21-1.56-.11-3.14-.02-5.14-.02Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)

export default IconLogo
