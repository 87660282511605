/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { Button, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: var(--green);
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: var(--slate);
    }
  }
`;
const StyledContactSection = styled.section`
  .contact-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
    overflow: auto;
  }

  form input,
  label,
  textarea {
    color: var(--green);
    font-family: var(--font-mono);
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 0.8rem;
  }

  .contact-button {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 20px;
  }
`;

const Contact = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const onChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/.netlify/functions/sendmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(formState),
      });

      console.log('RESPONSE: ' + response.status, response.statusText); // check the response status and text

      if (response.ok) {
        setSnackbarOpen(true);
        setFormState({
          name: '',
          email: '',
          subject: '',
          message: '',
        });

        return;
      }
    } catch (e) {
      alert(e);
    }
  };

  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection
      className='contact-inner'
      id='contact'
      ref={revealContainer}
    >
      <h2 className='numbered-heading'>SAY HI!</h2>
      <div className='contact-inner'>
        <h3 className='title'>CONTACT</h3>

        <form
          netlify
          data-netlify='true'
          name='contact'
          method='POST'
          netlify-honeypot='bot-field'
          onSubmit={submitForm}
          value='Contact'
        >
          <CustomTextField
            id='name'
            name='name'
            label='Name'
            value={formState.name}
            onChange={onChange}
            fullWidth
            margin='normal'
            required
          />
          <CustomTextField
            id='email'
            name='email'
            label='Email'
            type='email'
            value={formState.email}
            onChange={onChange}
            fullWidth
            margin='normal'
            required
          />
          <CustomTextField
            id='subject'
            name='subject'
            label='Subject'
            value={formState.subject}
            onChange={onChange}
            fullWidth
            margin='normal'
            required
          />
          <CustomTextField
            id='message'
            name='message'
            label='Message'
            value={formState.message}
            onChange={onChange}
            fullWidth
            multiline
            rows={4}
            margin='normal'
            color='primary'
            required
          />
          <Button
            className='contact-button'
            type='submit'
            variant='contained'
            color='primary'
          >
            Send
          </Button>
        </form>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        variant={'outlined'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity='success'>
          Thank you for your submission!
        </Alert>
      </Snackbar>
    </StyledContactSection>
  );
};

export default Contact;
