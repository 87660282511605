import React from 'react';
import styled from 'styled-components';
import { TopographicBackground } from '@components';
import bg from '../images/svg/bg.svg';

const StyledTopographic = styled.div`
  ${({ theme }) => theme.mixins.flexCenter};
  position: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-image: url('https://teamtinymachine.com/assets/img/topographic-bg.svg') !important;
  z-index: -1;
  opacity: .3;
`;

const StyledGradientOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background: linear-gradient(222deg, #757980, #033025);
  opacity: 0.2;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const Topographic = () => (
  <StyledTopographic>
    <div className='logo-wrapper'>
      <div id='topographic' />
      <StyledGradientOverlay />
    </div>
  </StyledTopographic>
);

export default Topographic;
